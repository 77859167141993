root {
    --main-bg-color: brown;
}

.gf-donation-window {
    width: 100%;
    max-width: 680px;
    background-color: #fff;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    margin: 0 auto;
    font-family: sans-serif;
    padding: 20px;
    min-height: 60vh;
}

@media screen and (min-width: 800px) {
    .gf-donation-window {
        padding: 62px 78px 28px;
    }
}

.group-section {
    margin: 40px 0 0;
}

.gf-input-line {
    display: flex;
    justify-content: space-between;
    margin: 18px 0 0;
}

.gf-input-line .gf-input-box.d-width15 {
    width: 15.8%;
}

.gf-input-line .gf-input-box.d-width23 {
    width: 23%;
}

.gf-input-line .gf-input-box.d-width48 {
    width: 48.25%;
}

.gf-input-line .gf-input-box.gf-cvv-box {
    max-width: 50px;
}

.gf-input-line .gf-input-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.gf-input-line .gf-input-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.gf-input-text {
    width: 100%;
    order: 2;
    display: block;
    margin: 10px 0 0;
    border: 1px solid #c4c3c3;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 16px;
    color: #3b3b3b;
    padding: 10px 8px;
    outline: none;
    -webkit-transition: border-color 0.1s, background-color 0.1s, color 0.1s;
    -moz-transition: border-color 0.1s, background-color 0.1s, color 0.1s;
    -ms-transition: border-color 0.1s, background-color 0.1s, color 0.1s;
    -o-transition: border-color 0.1s, background-color 0.1s, color 0.1s;
    transition: border-color 0.1s, background-color 0.1s, color 0.1s;
    -webkit-appearance: none;
}

.gf-input-text::placeholder {
    color: #ccc;
}

.gf-input-text:hover {
    border-color: #898989;
    background-color: #fff;
}

.gf-input-line .gf-input-box .gf-input-text + .gf-input-label {
    font-size: 13px;
    line-height: 13px;
    color: #4c4c4c;
    order: 1;
    -webkit-transition: color 0.1s;
    -moz-transition: color 0.1s;
    -ms-transition: color 0.1s;
    -o-transition: color 0.1s;
    transition: color 0.1s;
    cursor: pointer;
}

.gf-input-line .gf-input-box.error .gf-input-text {
    color: #e4551c;
    background-color: #fffaf8;
    border-color: #e4551c;
}

.gf-input-line .gf-input-box .gf-input-text:focus {
    background-color: #fff !important;
    border-color: #1a8ff5 !important;
    cursor: text;
    color: #3b3b3b !important;
}

.gf-input-line .gf-input-box .gf-input-text:focus + .gf-input-label {
    color: #1a8ff5 !important;
}

.gf-input-line .gf-input-box.error .gf-input-text + .gf-input-label {
    color: #e4551c;
}

.gf-box-heading {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: #4c4c4c;
    position: relative;
    padding: 0 0 0 35px;
}

.gf-box-heading .gf-box-heading-number {
    min-width: 22px;
    height: 22px;
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
    color: #fff;
    background-color: #24c141;
    text-align: center;
    padding: 4px;
    vertical-align: middle;
    margin: 0 7px 0 2px;
    position: absolute;
    top: 0;
    left: 0;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.gf-submit-line {
    margin: 53px 0 0;
    text-align: center;
}

.gf-submit-line .gf-submit-button-container {
    max-width: 100%;
    height: 60px;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
}

.gf-submit-button {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0;
    width: 320px;
    height: 60px;
    background-color: #1b8ff5;
    border: 2px solid #1b8ff5;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #fff;
    -webkit-transition: background-color 0.3s, color 0.3s, width 0.3s, border-color 0.3s;
    -moz-transition: background-color 0.3s, color 0.3s, width 0.3s, border-color 0.3s;
    -ms-transition: background-color 0.3s, color 0.3s, width 0.3s, border-color 0.3s;
    -o-transition: background-color 0.3s, color 0.3s, width 0.3s, border-color 0.3s;
    transition: background-color 0.3s, color 0.3s, width 0.3s, border-color 0.3s;
    outline: none;
    cursor: pointer;
}

.gf-submit-button:disabled {
    background-color: gray;
    border: 2px solid gray;
}

.donation-value-input {
    padding: 8px 4px;
    border-radius: 3px;
    height: 100%;
    width: 100%;
    border: 0;
    background: transparent;
    outline: 0;
    font-size: 30px;
    font-family: sans-serif;
    font-weight: normal;
    text-align: center;
    color: #1a8ff5;
}
.custom-value-eur {
    position: absolute;
    left: 10px;
    top: 10px;
}

.gf-content {
    margin: 4px 0 0;
}

.gf-content .gf-donation-boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
}

.gf-donation-box {
    width: 30%;
    border-radius: 3px;
    color: #4c4c4c;
    -webkit-transition: color 0.1s, background-color 0.1s, border-color 0.1s, font-weight 0.1s;
    -moz-transition: color 0.1s, background-color 0.1s, border-color 0.1s, font-weight 0.1s;
    -ms-transition: color 0.1s, background-color 0.1s, border-color 0.1s, font-weight 0.1s;
    -o-transition: color 0.1s, background-color 0.1s, border-color 0.1s, font-weight 0.1s;
    transition: color 0.1s, background-color 0.1s, border-color 0.1s, font-weight 0.1s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 20px 10px;
    position: relative;
    margin-bottom: 20px;
}

.amount-value {
    font-size: 30px;
    font-weight: 800;
    margin-left: 10px;
    display: flex;
    font-family: 'Solway', serif;
}

.amount-icon {
    width: 30px;
}
.euro-amount-icon {
    font-size: 14px;
    align-self: flex-start;
}

.another-amount {
    font-size: 20px;
    margin-left: 10px;
}

.gf-donation-box-active .another-amount {
    position: absolute;
    left: 0;
    top: 8px;
    color: #fff;
    font-size: 12px;
}

.gf-donation-box-amount {
    font-size: 30px;
}

.gf-donation-box:hover {
    background-color: #ffdde0;
}

.gf-donation-box-active {
    background: #fc0e21 !important;
}

.gf-donation-box-active.gf-donation-box {
    color: #fff !important;
}

@media screen and (max-width: 500px) {
    .gf-donation-box svg {
        height: 50px;
    }
    .gf-donation-box {
        margin: 10px 0;
    }
}

.gf-donation-custom {
    width: auto;
    display: flex;
    max-width: 100%;
}

.euro-custom {
    display: block;
    color: #fff;
    font-size: 24px;
    font-family: 'Solway', serif;
}

.selected-amount-icon {
    display: none;
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
}

.selected-amount-icon img {
    width: 100%;
}

.gf-donation-box-active .selected-amount-icon {
    display: block;
}

.donation-value-box {
    display: flex;
    justify-content: center;
}

.gf-donation-box-currency {
    font-size: 15px;
    font-weight: 400;
    position: relative;
}

.donation-custom-amount {
    outline: 0;
    background: transparent;
    border: 0;
    color: #1a8ff5;
    text-align: center;
    font-size: 30px;
    font-family: 'Solway', serif;
    color: #fff;
    height: 40px;
    flex-grow: 1;
    width: 100%;
    display: block;
    font-weight: 800;
}

.custom-amount-help-text {
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: 10px;
}

.gf-line-conainter {
    width: 100%;
    background-color: #fff;
    border: 1px solid #c4c3c3;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 18px 16px 16px;
    color: #6d6d6d;
    cursor: pointer;
}

.gf-yellow-line {
    border: 1px solid #ffd200;
    background-color: rgba(255, 210, 0, 0.05);
    color: #6d6d6d;
}

.gf-yellow-line:hover {
    color: #333;
}

.monthly-active {
    color: #1a8ff5 !important;
}

.monthly-label {
    margin-left: 10px;
}

.heart-checkout {
    margin-top: 40px;
    padding: 0 20px;
    width: 100%;
    max-width: 300px;
    margin: 40px auto;
}

.heart-checkout .thanks-name {
    font-size: 28px;
}

.payment-methods {
    display: flex;
    flex-direction: column;
}

.payment-name-container {
    display: inline-block;
    color: #6d6d6d;
    cursor: pointer;
}
.payment-flex {
    display: flex;
    align-items: center;
}

.payment-method-name {
    padding: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.payment-radio {
    width: 16px;
    height: 16px;
    border: 1px solid #e7e7e7;
    border-radius: 50%;
}

.payment-name-container:hover .payment-radio,
.payment-name-container:hover {
    color: #333;
    border-color: #333;
}

.payment-method-active .payment-name-container {
    color: #1a8ff5;
}

.payment-method-container {
    margin-bottom: 40px;
}

.method-icons {
    margin-left: 10px;
}

.method-icons img {
    height: 24px;
}

.payment-method {
}

.payment-note {
    margin-left: 10px;
}

.input-note {
    font-size: 14px;
}

.help-line {
    font-size: 14px;
    margin-bottom: 20px;
    justify-content: start;
    cursor: pointer;
    align-items: center;
}

.error-line {
    text-align: center;
    font-style: italic;
}

.StripeElement--invalid {
    border: red;
}
