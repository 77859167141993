:root {
    --space-vertical: 160px;
    --space-sides: 40px;
    --main-color: #ed3838;
}
@media screen and (max-width: 840px) {
    :root {
        --space-vertical: 60px;
        --space-sides: 40px;
    }
}

a {
    cursor: pointer;
}

.top-line {
    height: 10px;
    width: 100%;
    background: #1d368d;
}

.logo-container {
    text-align: center;
}

.logo-container img {
    width: 240px;
}

@media screen and (max-width: 600px) {
    .logo-container img {
        width: 120px;
    }
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.top-bar-link {
    display: inline-block;
    margin-left: 20px;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
}

.top-bar-link:hover {
    text-decoration: underline;
}

@media screen and (max-width: 600px) {
    .top-bar {
        flex-direction: column;
    }
    .top-bar-link {
        margin: 0 10px;
        margin-top: 20px;
        font-size: 14px;
    }
}

.home-page h3 {
    margin: 0 0 20px;
    font-family: 'Solway', serif;
    font-size: 34px;
    font-weight: normal;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
}

.page-header {
    margin: 60px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    max-width: 890px;
    margin: 0 auto;
}

.header-text {
    width: 50%;
    margin: 0px var(--space-sides);
    text-align: center;
}

.header-text > img {
    width: 100%;
}

.donation-btn-top {
    margin-top: 40px;
}

.header-heart {
    width: 50%;

    max-width: 580px;
    margin: 40px var(--space-sides);
    position: relative;
}

@media (max-width: 600px) {
    .page-header {
        display: block;
    }

    .header-heart,
    .header-text {
        width: auto;
    }
}

.page-title {
    font-family: 'Solway', serif;
    line-height: 1;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    margin-top: 0;
}

.page-main-title {
    font-size: 30px;
    text-align: center;
    font-family: 'Solway', serif;
}

.title-second {
    margin-top: 20px;
    margin-bottom: 40px;
}

@media screen and (max-width: 600px) {
    .page-title {
        margin-top: 40px;
    }
}

@media screen and (min-width: 800px) {
    .page-title {
        font-size: 25px;
    }
}

.donor-sharing {
    text-align: center;
    padding: 20px 20px 0 20px;
    margin-bottom: -30px;
}

.donor-sharing-name {
    font-weight: bold;
}

.align-donation {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.donation-button-all {
    text-align: center;
    margin-bottom: 40px;
}

.donation-button-container {
    width: 210px;
    height: 45px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
}

.donate-btn {
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    height: 100%;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-size: 16px;
    font-weight: bold;
    z-index: 2;
    cursor: pointer;
}

.donate-btn-animate {
    animation: move 2s ease-in-out infinite;
}

.button-line {
    position: absolute;
    left: 0;
    top: 0;
    width: 210px;
    z-index: 0;
}

.button-heart {
    position: absolute;
    width: 44px;
    right: -20px;
    top: -14px;
    z-index: 0;
}

.donate-btn:hover {
    color: var(--main-color);
}

.donate-btn:hover .button-line,
.donate-btn:hover .button-heart {
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

.donate-help-text {
    color: var(--main-color);
    margin-top: 10px;
    font-family: 'Solway', serif;
    font-size: 12px;
    text-transform: uppercase;
}

.center-title {
    text-align: center !important;
    margin: 0 0 20px;
    text-transform: none !important;
}

.donation-stats {
    text-align: center;
    font-size: 20px;
    max-width: 100%;
    margin: 20px var(--space-sides);
    padding: 20px 0;
    border-radius: 59px 64px 34px 94px;
    margin-top: 60px;
    color: #000;
    justify-content: center;
}

.donation-stats-text {
    margin-left: 10px;
    font-size: 20px;
    color: #000;
    margin-bottom: 20px;
}

.stats-image {
    margin: 10px;
}

.stats-image img {
    width: 240px;
    max-width: 100%;
}

.bottom-cloud {
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
    margin-top: 40px;
}
.bottom-cloud img {
    width: 120px;
}

.button-container {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 60px;
}

.btn-primary {
    background: #4bce7a;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.content-size {
    max-width: 970px;
    margin: 0 auto;
}

.content-size-small {
    max-width: 700px;
    box-sizing: content-box;
}

.know-more-container {
    margin-top: 60px;
    text-align: center;
}

.hide-form {
    position: absolute;
    left: -9000px;
}

.photo-container {
    position: relative;
}

.big-number {
    font-size: 28px;
    color: #e44848;
}

.numbers-title {
    margin-bottom: 40px !important;
}

.stats-list {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 890px;
}

.stats-list li {
    list-style-type: none;
    border: 2px solid #cb1b14;
    border-radius: 3px;
    width: 30%;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    flex-wrap: wrap;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .stats-list li {
        width: 40%;
    }
}

.footer {
    text-align: center;
    font-size: 14px;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #333;
    color: #fff;
}
.footer a {
    color: #fff;
}

.languages {
    margin-top: 20px;
}

.language-selector {
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
    text-decoration: underline;
}

.help-child-button {
    padding: 10px;
    width: 100%;
    background: #ffffff;
    font-size: 16px;
    color: #158bef;
    border: 2px solid #158bef;
    margin-top: 10px;
}

.logo-casa-damiao {
    max-width: 500px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.logo-casa-damiao img {
    max-width: 100%;
    width: 450px;
}

.hearts-image {
    width: 100%;
}

.hearts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0.7;
}

.heart-container {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    animation: move 2s ease-in-out infinite;
}

.heart {
    width: 100%;
    z-index: 3;
}

@keyframes move {
    2% {
        transform: scale(1);
    }
    12% {
        transform: scale(1.05);
    }
    17% {
        transform: scale(1);
    }

    22% {
        transform: scale(1.1);
    }
    32% {
        transform: scale(1);
    }
}

.thanks-container {
    position: absolute;
    left: 25px;
    right: 25px;
    bottom: 0;
    z-index: 2;
    top: 0;
    text-align: center;
    padding-top: 17%;
    z-index: 6;
    color: #fff;
    font-family: 'Patrick Hand SC', cursive;
}

.thanks {
    font-size: 20px;
}

.thanks-name {
    font-weight: bold;
    font-size: 36px;
}

@media screen and (max-width: 800px) {
    .thanks-name {
        font-size: 30px;
    }
}

.share-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.share-btn {
    width: 50px;
    height: 50px;
}

.share-btn-container {
    margin: 0 10px;
    cursor: pointer;
    position: relative;
}

.share-help {
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.share-help-strong {
    font-size: 16px;
}

.share-help-form-open {
    padding-top: 20px;
}

.second-title {
    text-align: center;
    font-family: 'Solway', serif;
    margin-top: 120px !important;
    padding-top: 120px;
    padding-bottom: 120px;
}

.second-title h2 {
    font-weight: normal !important;
    line-height: 1.2;
    font-size: 45px;
}

.tooltip-share {
    position: absolute;
    top: -50px;
    background: #333;
    color: #fff;
    padding: 10px;
    width: 140px;
    text-align: center;
    left: -40px;
    border-radius: 4px;
}

.companies-container {
    margin-top: 60px;
    padding: 20px;
    background: #333;
    color: #fff;
}

.company-logo {
    margin: 20px;
}
.company-logo img {
    width: 120px;
}
.companies-title {
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 20px;
    text-align: center;
}

.companies-logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.content-side-by-side {
    display: flex;
    justify-content: center;
    align-items: start;
    font-size: 20px;
    margin: var(--space-vertical) auto;
    max-width: 880px;
}

.two-content {
    margin-right: var(--space-sides);
    line-height: 1.2;
}

.child-info-container {
    position: relative;
    width: 300px;
    margin-bottom: 20px;
}

.children-container {
    display: flex;
    margin-top: 60px;
    margin-bottom: 60px;
}

.child-photo {
    width: 100%;
    border-radius: 3px;
}

.child-info {
    padding: 10px;
    font-size: 16px;
    overflow: visible;
    text-align: center;
}

.child-question {
    text-align: center;
    font-family: 'Solway', serif;
    color: var(--main-color);
    font-size: 16px;
    margin-bottom: 6px;
    text-transform: uppercase;
    margin-top: 6px;
}

.child-name {
    text-align: center;
    font-family: 'Solway', serif;
    font-weight: bold;
    text-transform: uppercase;
}

.child-info-top {
    margin-bottom: 40px;
}

@media screen and (max-width: 840px) {
    .content-side-by-side {
        flex-direction: column;
        margin-top: 0 !important;
    }

    .two-content {
        width: auto;
        margin: 0 var(--space-sides);
    }

    .photo-1 {
        padding: 0;
        width: 100%;
        margin: 0 0 20px 0 !important;
        max-width: 100%;
    }

    .child-photo {
        padding: 0;
        border-radius: 0px;
    }

    .child-info {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0px;
    }

    .child-info-container {
        width: 100%;
    }
}

.bottom-space {
    margin-bottom: 40px;
}

.big-area {
    display: flex;
    justify-content: center;
    align-items: center;
}

.big-area-color {
    background: var(--main-color);
    color: #fff;
}

.text-center {
    font-size: 24px;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.text-center h3 {
    text-align: center;
    margin-bottom: 40px;
}

.text-center-space {
    margin: 40px auto;
    padding: 60px var(--space-sides);
}

.cloud-1 {
    text-align: center;
    margin: 40px 0 40px 0;
}

.cloud-1 img {
    width: 50px;
}

.company-share-message-container {
    padding: 20px var(--space-sides);
    margin-top: 40px;
}

.company-share-message {
    max-width: 800px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
}

.company-share-name {
    margin-top: 20px;
    text-align: center;
}

.company-share-name a {
    color: var(--main-color);
}

.company-share-logo {
    margin-top: 20px;
}

.company-share-logo img {
    max-width: 240px;
    max-height: 240px;
}

.aspas {
    font-size: 26px;
}

.page-title-thanks {
    text-align: center;
    padding: 0 40px;
    margin-top: 40px !important;
}

.loading-page {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #e4e4e447#3a3a3a47;
    z-index: 2;
}

.thanks-page .header-heart {
    margin-top: 0;
}

.mbway-page {
    text-align: center;
}

.mbway-page-logo {
    text-align: center;
    margin-bottom: 40px;
}

.mbway-page-logo img {
    max-width: 100%;
    width: 200px;
}

.mbway-page-text {
    margin-bottom: 20px;
}

.mbway-page-phone {
    font-weight: bold;
}

.mb-data {
    text-align: left;
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

.mb-titles {
    font-weight: bold;
}

.iban-icon {
    font-weight: bold;
    display: inline-block;
    margin-left: 10px;
    color: #198ff5;
    border: 1px solid #198ff5;
    padding: 2px;
}

.iban-big-logo-container {
    text-align: center;
}

.iban-big-logo {
    font-weight: bold;
    display: inline-block;
    margin-left: 10px;
    color: #198ff5;
    padding: 2px;
    font-size: 40px;
    margin-bottom: 40px;
}

.iban-page-iban {
    font-size: 20px;
}

.iban-data {
    text-align: left;
    margin: 0 auto;
    display: inline-block;
}

.iban-data-item {
    margin-bottom: 10px;
}

.iban-table-title {
    font-weight: bold;
    padding: 10px;
    text-align: right;
}

.iban-page-text {
    margin-bottom: 20px;
}

.iban-label {
    font-size: 14px;
    margin-right: 20px;
    margin-bottom: 5px;
}

.slider-names-container {
    margin: 0 auto;
    position: relative;
    max-width: 600px;
    display: flex;
    justify-content: center;
}

.slider-names {
    margin: 0 40px;
    position: relative;
    flex-grow: 1;
}

.render-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ed3838;
    margin-top: -20px;
}

.render-track {
    height: 10px;
    width: 100%;
    background: #ccc;
    border-radius: 4px;
}

.render-track-line {
    height: 5px;
    width: 100%;
    border-radius: 4px;
    height: 100%;
}

.small-heart-range {
    background-color: #ed3838;
    display: inline-block;
    height: 20px;
    position: relative;
    top: 0;
    transform: rotate(-45deg);
    width: 20px;
}

.small-heart-range:before,
.small-heart-range:after {
    content: '';
    background-color: #ed3838;
    border-radius: 50%;
    height: 20px;
    position: absolute;
    width: 20px;
}

.small-heart-range:before {
    top: -7px;
    left: 0;
}

.small-heart-range:after {
    left: 7px;
    top: 0;
}

.end-range {
    height: 10px;
    width: 10px;
    position: absolute;
    right: -7px;
    background: #ffa0a0;
    top: 0;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}

.you-message {
    position: absolute;
    bottom: -30px;
    left: -20px;
    white-space: nowrap;
}

.add-your-name {
    top: -20px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
    margin-top: 20px;
}

.add-your-name-btn {
    background: #fff;
    border: 1px solid #ed3838;
    color: #333;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
}

.head-line {
    margin-bottom: 20px;
}
