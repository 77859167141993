html,
body {
    margin: 0;
    padding: 0;
}
* {
    box-sizing: border-box;
}

body {
    font-family: 'Titillium Web', sans-serif;
    color: #333;
    text-align: justify;
    line-height: 1.1;
    font-weight: 300;
}

h1.title {
    font-size: 54px;
    text-align: center;
    margin-top: 60px;
    font-family: 'Pacifico', cursive;
    line-height: 1;
}

h2.title {
    font-size: 38px;
    text-align: center;
}

h3.title {
    font-size: 24px;
    margin: 60px 0 10px;
}

a {
    text-decoration: underline;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}
