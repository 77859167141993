.admin-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.admin-container a {
    color: blue;
}

.admin-messages {
    margin: 20px 0;
    border: 1px solid #e0e0e0;
    padding: 20px;
}

.admin-delete-button {
    color: red;
    cursor: pointer;
}

.admin-form-container > div {
    margin: 20px 0;
}

.admin-form-container input {
    width: 80%;
    padding: 4px;
    margin: 10px 0;
}

.admin-image-preview {
    width: 200px;
}

.admin-error {
    color: red;
}

.donor-visible-false {
    color: red;
}

.donor-visible-true {
    color: green;
}

.admin-table {
    width: 100%;
    margin-top: 40px;
    border-spacing: 0;
    word-break: break-all;
}

.admin-table tr:nth-child(even) {
    background: #f5f5f5;
}

.admin-table tr:hover {
    background-color: #cacaca;
}

.admin-table td {
    padding: 10px 4px;
    border: 0;
}

.amount-table {
    white-space: nowrap;
}

.admin-stats {
    margin: 20px 0;
    display: flex;
    background: aliceblue;
    padding: 20px;
}

@media screen and (max-width: 700px) {
    .admin-stats {
        flex-direction: column;
    }
}

.admin-stats > div {
    margin: 10px;
    flex-grow: 1;
}

.tab-button {
    margin: 4px;
    background: #d6e5f7;
    padding: 10px;
    border: 0;
    cursor: pointer;
}

.tab-true {
    background: #64adff;
}

.btn-operations {
    margin: 10px;
    cursor: pointer;
}

.admin-delete-ontable {
    font-size: 12px;
    cursor: pointer;
}

.sponsor-item {
    margin-bottom: 40px !important;
}

.sponsor-image-preview {
    width: 100px;
}

.sponsor-image-preview img {
    width: 100%;
}

.save-bnt-admin {
    margin: 40px 0px;
    display: block;
}

.donor-anonym {
    color: #b1765f;
}
